import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DeviceType} from 'api/entities';
import {Alcedo7User} from '../user/avelon-user.service';
import {FullDevice} from './device.interface';

export const DEVICE_TYPES: Record<DeviceType, {model: DeviceType; image: string; route: string; iotLicenseSize?: number}> = {
  [DeviceType.IOT_COMTAC_DI]: {
    model: DeviceType.IOT_COMTAC_DI,
    image: 'unknown.svg',
    route: 'sensor'
  },
  [DeviceType.IOT_COMTAC_LD1]: {
    model: DeviceType.IOT_COMTAC_LD1,
    image: 'comtac-ld1.png',
    route: 'sensor'
  },
  [DeviceType.CIT_SL]: {
    model: DeviceType.CIT_SL,
    image: 'unknown.svg',
    route: 'legacy'
  },
  [DeviceType.CIT_XT]: {
    model: DeviceType.CIT_XT,
    image: 'unknown.svg',
    route: 'legacy'
  },
  [DeviceType.CIT_XT_LAN]: {
    model: DeviceType.CIT_XT_LAN,
    image: 'unknown.svg',
    route: 'legacy'
  },
  [DeviceType.DVR]: {
    model: DeviceType.DVR,
    image: 'unknown.svg',
    route: 'legacy'
  },
  [DeviceType.IOT_GENERIC]: {
    model: DeviceType.IOT_GENERIC,
    image: 'iot.svg',
    route: 'sensor'
  },
  [DeviceType.IOT_BUTTON]: {
    model: DeviceType.IOT_BUTTON,
    image: 'button.svg',
    route: 'sensor'
  },
  [DeviceType.IOT_TRACY]: {
    model: DeviceType.IOT_TRACY,
    image: 'tracy.png',
    route: 'sensor'
  },
  [DeviceType.IOT_TRACY2]: {
    model: DeviceType.IOT_TRACY2,
    image: 'tracy.png',
    route: 'sensor'
  },
  [DeviceType.IOT_GATEWAY]: {
    model: DeviceType.IOT_GATEWAY,
    image: 'gateway.svg',
    route: 'sensor'
  },
  [DeviceType.IOT_WATER_LEAK_DETECTOR]: {
    model: DeviceType.IOT_WATER_LEAK_DETECTOR,
    image: 'leaksense.png',
    iotLicenseSize: 5,
    route: 'sensor'
  },
  [DeviceType.IOT_THERMOSENSE_PT1000]: {
    model: DeviceType.IOT_THERMOSENSE_PT1000,
    image: 'thermosense.png',
    iotLicenseSize: 5,
    route: 'sensor'
  },
  [DeviceType.IOT_THERMOSENSE_ONE_WIRE]: {
    model: DeviceType.IOT_THERMOSENSE_ONE_WIRE,
    image: 'thermosense.png',
    iotLicenseSize: 5,
    route: 'sensor'
  },
  [DeviceType.IOT_THERMOSENSE_OUTDOOR]: {
    model: DeviceType.IOT_THERMOSENSE_OUTDOOR,
    image: 'thermosense.png',
    iotLicenseSize: 2,
    route: 'sensor'
  },
  [DeviceType.IOT_THERMOSENSE_FRIGO]: {
    model: DeviceType.IOT_THERMOSENSE_FRIGO,
    image: 'thermosense.png',
    iotLicenseSize: 2,
    route: 'sensor'
  },
  [DeviceType.IOT_WATER_METER]: {
    model: DeviceType.IOT_WATER_METER,
    image: 'gwf-water-meter.png',
    route: 'sensor'
  },
  [DeviceType.IOT_BINARY]: {
    model: DeviceType.IOT_BINARY,
    image: 'binary-10-4.png',
    iotLicenseSize: 10,
    route: 'sensor'
  },
  [DeviceType.IOT_QUALITY_SENSOR]: {
    model: DeviceType.IOT_QUALITY_SENSOR,
    image: 'decentlab-co2.png',
    route: 'sensor'
  },
  [DeviceType.IOT_THERMO_VALVE]: {
    model: DeviceType.IOT_THERMO_VALVE,
    image: 'thermo-valve.png',
    route: 'sensor'
  },
  [DeviceType.BEETLE_SCADA]: {
    model: DeviceType.BEETLE_SCADA,
    image: 'beetle-docker.svg',
    route: 'beetle'
  },
  [DeviceType.BEETLE_SLAM1]: {
    model: DeviceType.BEETLE_SLAM1,
    image: 'unknown.svg',
    route: 'legacy'
  },
  [DeviceType.BEETLE_T3]: {
    model: DeviceType.BEETLE_T3,
    image: 'beetle-t3.png',
    route: 'beetle'
  },
  [DeviceType.BEETLE_T4]: {
    model: DeviceType.BEETLE_T4,
    image: 'beetle-t4.png',
    route: 'beetle'
  },
  [DeviceType.BEETLE_T5]: {
    model: DeviceType.BEETLE_T5,
    image: 'beetle-t5.png',
    route: 'beetle'
  },
  [DeviceType.BEETLE_T6]: {
    model: DeviceType.BEETLE_T6,
    image: 'beetle-t6.png',
    route: 'beetle'
  },
  [DeviceType.MQTT_WAGO_750]: {
    model: DeviceType.MQTT_WAGO_750,
    image: 'wago-750-8212.png',
    route: 'wago'
  },
  [DeviceType.TABLET]: {
    model: DeviceType.TABLET,
    image: 'tablet.png',
    route: 'tablet'
  },
  [DeviceType.IOT_TEK_766]: {
    model: DeviceType.IOT_TEK_766,
    image: 'tekelek-tek766.png',
    route: 'sensor'
  },
  [DeviceType.IOT_MULTICAL]: {
    model: DeviceType.IOT_MULTICAL,
    image: 'multical.png',
    route: 'sensor'
  },
  [DeviceType.IOT_BOSCH_TPS_110]: {
    model: DeviceType.IOT_BOSCH_TPS_110,
    image: 'bosch-tps-110.png',
    route: 'sensor'
  },
  [DeviceType.UNKNOWN]: {
    model: DeviceType.UNKNOWN,
    image: 'unknown.svg',
    route: 'legacy'
  },
  [DeviceType.BEETLE_VIRT]: {
    model: DeviceType.BEETLE_VIRT,
    image: 'beetle-vm.svg',
    route: 'beetle'
  },
  [DeviceType.WEATHER]: {
    model: DeviceType.WEATHER,
    image: 'weather.svg',
    route: 'weather'
  },
  [DeviceType.IOT_WISELY_STANDARD]: {
    model: DeviceType.IOT_WISELY_STANDARD,
    image: 'wisely.png',
    iotLicenseSize: 2,
    route: 'sensor'
  },
  [DeviceType.IOT_WISELY_ALLSENSE]: {
    model: DeviceType.IOT_WISELY_ALLSENSE,
    image: 'wisely.png',
    iotLicenseSize: 10,
    route: 'sensor'
  },
  [DeviceType.IOT_WISELY_CARBONSENSE]: {
    model: DeviceType.IOT_WISELY_CARBONSENSE,
    image: 'wisely.png',
    iotLicenseSize: 5,
    route: 'sensor'
  },
  [DeviceType.TRACKER_FMC001]: {
    model: DeviceType.TRACKER_FMC001,
    image: 'fmc001.png',
    route: 'tracker'
  },
  [DeviceType.TRACKER_FMC640]: {
    model: DeviceType.TRACKER_FMC640,
    image: 'fmc640.png',
    route: 'tracker'
  },
  [DeviceType.VIRTUAL_DEVICE]: {
    model: DeviceType.VIRTUAL_DEVICE,
    image: 'unknown.svg',
    route: 'virtual'
  },
  [DeviceType.ON_SITE_PANEL_SERVER]: {
    model: DeviceType.ON_SITE_PANEL_SERVER,
    image: 'lumina-operation-center.webp',
    route: 'onSitePanelServer'
  },
  [DeviceType.IOT_ELSYS_ERS2]: {
    model: DeviceType.IOT_ELSYS_ERS2,
    image: 'ers2.png',
    route: 'sensor'
  },
  [DeviceType.IOT_ELSYS_EMS2_DESK]: {
    model: DeviceType.IOT_ELSYS_EMS2_DESK,
    image: 'ems2-desk.png',
    route: 'sensor'
  }
};

@Injectable({providedIn: 'root'})
export class DeviceService {
  constructor(private router: Router) {}

  static BEETLE_HARDWARE_MODELS = [
    DEVICE_TYPES.BEETLE_T3.model,
    DEVICE_TYPES.BEETLE_T4.model,
    DEVICE_TYPES.BEETLE_T5.model,
    DEVICE_TYPES.BEETLE_T6.model
  ];
  static BEETLE_MODELS = [
    DEVICE_TYPES.BEETLE_T3.model,
    DEVICE_TYPES.BEETLE_T4.model,
    DEVICE_TYPES.BEETLE_T5.model,
    DEVICE_TYPES.BEETLE_T6.model,
    DEVICE_TYPES.BEETLE_SCADA.model,
    DEVICE_TYPES.BEETLE_VIRT.model
  ];
  static BEETLE_OPC_MODELS = [DEVICE_TYPES.BEETLE_T5.model, DEVICE_TYPES.BEETLE_T6.model, DEVICE_TYPES.BEETLE_SCADA.model];
  static BEETLE_LORA_MODELS = [
    DEVICE_TYPES.BEETLE_T4.model,
    DEVICE_TYPES.BEETLE_T5.model,
    DEVICE_TYPES.BEETLE_T6.model,
    DEVICE_TYPES.BEETLE_VIRT.model
  ];
  static IOT_MODELS = [
    DEVICE_TYPES.IOT_COMTAC_DI.model,
    DEVICE_TYPES.IOT_COMTAC_LD1.model,
    DEVICE_TYPES.IOT_TRACY.model,
    DEVICE_TYPES.IOT_TRACY2.model,
    DEVICE_TYPES.IOT_GENERIC.model,
    DEVICE_TYPES.IOT_BUTTON.model,
    DEVICE_TYPES.IOT_WATER_LEAK_DETECTOR.model,
    DEVICE_TYPES.IOT_TEK_766.model,
    DEVICE_TYPES.IOT_MULTICAL.model,
    DEVICE_TYPES.IOT_BOSCH_TPS_110.model,
    DEVICE_TYPES.IOT_THERMOSENSE_PT1000.model,
    DEVICE_TYPES.IOT_THERMOSENSE_ONE_WIRE.model,
    DEVICE_TYPES.IOT_THERMOSENSE_OUTDOOR.model,
    DEVICE_TYPES.IOT_THERMOSENSE_FRIGO.model,
    DEVICE_TYPES.IOT_WATER_METER.model,
    DEVICE_TYPES.IOT_BINARY.model,
    DEVICE_TYPES.IOT_QUALITY_SENSOR.model,
    DEVICE_TYPES.IOT_WISELY_STANDARD.model,
    DEVICE_TYPES.IOT_WISELY_ALLSENSE.model,
    DEVICE_TYPES.IOT_WISELY_CARBONSENSE.model,
    DEVICE_TYPES.IOT_THERMO_VALVE.model,
    DEVICE_TYPES.IOT_ELSYS_ERS2.model,
    DEVICE_TYPES.IOT_ELSYS_EMS2_DESK.model
  ];

  static IOT_WISELY_MODELS = [
    DEVICE_TYPES.IOT_WISELY_STANDARD.model,
    DEVICE_TYPES.IOT_WISELY_ALLSENSE.model,
    DEVICE_TYPES.IOT_WISELY_CARBONSENSE.model
  ];
  static TRACKERS_MODELS = [DEVICE_TYPES.TRACKER_FMC001.model, DEVICE_TYPES.TRACKER_FMC640.model];
  static WAGO_MODELS = [DEVICE_TYPES.MQTT_WAGO_750.model];
  static LEGACY_MODELS = [
    DEVICE_TYPES.CIT_SL.model,
    DEVICE_TYPES.CIT_XT.model,
    DEVICE_TYPES.CIT_XT_LAN.model,
    DEVICE_TYPES.BEETLE_SLAM1.model,
    DEVICE_TYPES.DVR.model,
    DEVICE_TYPES.UNKNOWN.model
  ];
  static VIRTUAL_DEVICE_MODELS = [DEVICE_TYPES.VIRTUAL_DEVICE.model];
  static ON_SITE_PANEL_SERVER_MODELS = [DEVICE_TYPES.ON_SITE_PANEL_SERVER.model];

  static getDeviceIcon(model: string): string {
    const modelEntry = DEVICE_TYPES[model] || DEVICE_TYPES.UNKNOWN;
    return 'assets/images/devices/' + modelEntry.image;
  }

  static hasLabel(input: {model?: DeviceType}): boolean {
    return (
      !DeviceService.isElsysErs2(input) &&
      !DeviceService.isElsysEms2Desk(input) &&
      !DeviceService.isThermoValve(input) &&
      (DeviceService.isBeetleHardware(input) ||
        DeviceService.isIoTDevice(input) ||
        DeviceService.isIoTGateway(input) ||
        DeviceService.isTracker(input))
    );
  }

  static hasQRCode(input: {model?: DeviceType}): boolean {
    return (
      DeviceService.isIoTDevice(input) || DeviceService.isIoTGateway(input) || DeviceService.isTracker(input) || DeviceService.isWAGODevice(input)
    );
  }

  static isBeetle(input: {model?: DeviceType}): boolean {
    return DeviceService.BEETLE_MODELS.indexOf(input.model) > -1;
  }

  static isWAGODevice(input: {model?: DeviceType}): boolean {
    return DeviceService.WAGO_MODELS.indexOf(input.model) > -1;
  }

  static isTracker(input: {model?: DeviceType}): boolean {
    return DeviceService.TRACKERS_MODELS.indexOf(input.model) > -1;
  }

  static isVirtualDevice(input: {model?: DeviceType}): boolean {
    return DeviceService.VIRTUAL_DEVICE_MODELS.indexOf(input.model) > -1;
  }

  static isOnSitePanelServer(input: {model?: DeviceType}): boolean {
    return DeviceService.ON_SITE_PANEL_SERVER_MODELS.indexOf(input.model) > -1;
  }

  static isBeetleHardware(input: {model?: DeviceType}): boolean {
    return DeviceService.BEETLE_HARDWARE_MODELS.indexOf(input.model) > -1;
  }

  static isBeetleOPC(input: {model?: DeviceType}): boolean {
    return DeviceService.BEETLE_OPC_MODELS.indexOf(input.model) > -1;
  }

  static isIoTDevice(input: DeviceType | {model?: DeviceType}): boolean {
    const model = typeof input === 'string' ? input : input.model;
    return DeviceService.IOT_MODELS.indexOf(model as DeviceType) > -1;
  }

  static isIoTButton(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.IOT_BUTTON.model;
  }

  static isIoTGateway(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.IOT_GATEWAY.model;
  }

  static isIoTGeneric(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.IOT_GENERIC.model;
  }

  static isComtacLD(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.IOT_COMTAC_LD1.model;
  }

  static isIotTek766(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.IOT_TEK_766.model;
  }

  static isMultical(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.IOT_MULTICAL.model;
  }

  static isIoTBoschTPS110(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.IOT_BOSCH_TPS_110.model;
  }

  static isThermoValve(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.IOT_THERMO_VALVE.model;
  }

  static isLoRaWANCapableBeetle(input: {model?: DeviceType}): boolean {
    return DeviceService.BEETLE_LORA_MODELS.indexOf(input.model) > -1;
  }

  static isWisely(input: {model?: DeviceType}): boolean {
    return DeviceService.IOT_WISELY_MODELS.indexOf(input.model as any) > -1;
  }

  static isWiselyV3(input: FullDevice): boolean {
    // Check if given version is >= 3.1
    const version = (input.softwareVersion || '').split('.');
    return !(version.length < 2 || parseInt(version[0], 10) < 3 || parseInt(version[1], 10) < 1);
  }

  static isWiselyAllSense(device: {model?: DeviceType}): boolean {
    return device.model === DEVICE_TYPES.IOT_WISELY_ALLSENSE.model;
  }

  static isThermoSense(input: {model?: DeviceType}): boolean {
    return (
      input.model === DEVICE_TYPES.IOT_THERMOSENSE_PT1000.model ||
      input.model === DEVICE_TYPES.IOT_THERMOSENSE_ONE_WIRE.model ||
      input.model === DEVICE_TYPES.IOT_THERMOSENSE_OUTDOOR.model ||
      input.model === DEVICE_TYPES.IOT_THERMOSENSE_FRIGO.model
    );
  }

  static isTracy(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.IOT_TRACY.model;
  }

  static isTracy2(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.IOT_TRACY2.model;
  }

  static isWaterLeakDetector(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.IOT_WATER_LEAK_DETECTOR.model;
  }

  static isWatteco(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.IOT_BINARY.model;
  }

  static isWeatherDevice(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.WEATHER.model;
  }

  static isLegacy(input: {model?: DeviceType}): boolean {
    return DeviceService.LEGACY_MODELS.indexOf(input.model as any) > -1;
  }

  static isTablet(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.TABLET.model;
  }

  static isElsysErs2(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.IOT_ELSYS_ERS2.model;
  }

  static isElsysEms2Desk(input: {model?: DeviceType}): boolean {
    return input.model === DEVICE_TYPES.IOT_ELSYS_EMS2_DESK.model;
  }

  static canImportData(input: {model?: DeviceType}): boolean {
    return DeviceService.isBeetle(input) || DeviceService.isIoTDevice(input) || DeviceService.isVirtualDevice(input);
  }

  goToDevice(device: {id?: number; clientId?: number; model?: DeviceType}, newTab?: boolean) {
    const stateParams = {
      deviceId: device.id,
      clientId: device.clientId || (Alcedo7User.selectedClient ? Alcedo7User.selectedClient.id : Alcedo7User.currentUser.clientId),
      deviceType: DEVICE_TYPES[device.model].route
    };
    const route = ['client', stateParams.clientId, 'devices', stateParams.deviceType, stateParams.deviceId, 'configuration'];
    if (newTab) {
      window.open(location.origin + '/' + route.join('/'), '_blank', 'noreferrer');
    } else {
      this.router.navigate(route);
    }
  }
}
